import {getStationList} from "@/services/smrtResource.service";

const state = () => ({
  stationId: null,
  stationName: null,
  lineId: null,
  lineName: null,
  zoneId: null,
  zoneName: null,

  // 缓存站点信息
  _listStationCache: []
});

const getters = {
  isSelected: (state) => !!state.stationId,
  id: (state) => state.stationId,
  lineId: (state) => state.lineId,
  name: (state) => state.stationName,
  lineName: (state) => state.lineName,
  zoneId: (state) => state.zoneId,
  zoneName: (state) => state.zoneName,

  listStationCache: (state) => state._listStationCache,
};

const mutations = {
   set(state, { stationId, stationName, lineId, lineName }) {
    state.stationId = stationId;
    state.stationName = stationName;
    state.lineId = lineId;
    state.lineName = lineName;
  },
  setZone(state, {zoneId, zoneName}) {
     state.zoneName = zoneName;
     state.zoneId = zoneId;
  },
  setStationsCache(state, cache) {
     state._listStationCache = cache;
  },
  reset(state) {
    state.stationId = null;
    state.stationName = null;
    state.lineId = null;
    state.lineName = null;
    state.zoneName = null;
    state.zoneId = null;
  },
};

const actions = {
  changeStation: function({commit, getters}, stationInfo) {
    let stationId = stationInfo.stationId;
    let stationName = stationInfo.stationName;
    let lineId = stationInfo.lineId;
    let lineName = stationInfo.lineName;

    function setZone() {
      const currentStation = getters['listStationCache'].find(station => station.stationId === stationId);
      const zones = currentStation.zoneStations;
      const zone = zones.find(zone => zone.lineId === lineId);

      if (zone) {
        commit("setZone", {
          zoneId: zone.zoneId,
          zoneName: zone.zoneName
        });
      }

    }

    getStationList({ skip: 0, take: 100 }).then(res => {
      if (res.success) {
        const stations = res.payload.list;
        commit("setStationsCache", stations); // 缓存站点信息,更新完缓存之后在更新一下zone信息
        setZone();
      }
    });

    setZone();

    if (stationId !== null) {
      commit("set", {
        stationId,
        stationName,
        lineId,
        lineName,
      });
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
